import { PHONE_NUMBER_RADIO } from "../templates/strings";

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};

export const normalizeNumber = (numberStr) => {
  if (numberStr.startsWith("+0")) {
    return numberStr.replace("+", "");
  }
  return numberStr;
};

export const isValidPhoneNumber = (phoneNumber, verificationMode) => {
  let regex = "";
  let isValidNumber = false;
  switch (verificationMode) {
    case PHONE_NUMBER_RADIO.STANDARD:
      isValidNumber = true;
      break;
    case PHONE_NUMBER_RADIO.LIGHT:
      regex = /^\+\d{6,}$/;
      if (regex.test(phoneNumber)) {
        isValidNumber = true;
      }
      break;
    case PHONE_NUMBER_RADIO.VERIFY:
      regex = /^(?:\+([1-9]\d{0,2})|00([1-9]\d{0,2})|0)(\d{9,11})$/;
      if (regex.test(phoneNumber)) {
        isValidNumber = true;
      }
      break;
    default:
      break;
  }
  return isValidNumber;
};

const codes = [
  {
    country: "Luxemburg",
    countryCodes: ["352"],
    isoCode2: "LU",
    isoCode3: "LUX"
  },
  {
    country: "Austria",
    countryCodes: ["43"],
    isoCode2: "AT",
    isoCode3: "AUT"
  },
  {
    country: "Switzerland",
    countryCodes: ["41"],
    isoCode2: "CH",
    isoCode3: "CHE"
  },
  {
    country: "Netherlands",
    countryCodes: ["31"],
    isoCode2: "NL",
    isoCode3: "NLD"
  },
  {
    country: "France",
    countryCodes: ["33"],
    isoCode2: "FR",
    isoCode3: "FRA"
  },
  {
    country: "Germany",
    countryCodes: ["49"],
    isoCode2: "DE",
    isoCode3: "DEU"
  },
  {
    country: "Belgium",
    countryCodes: ["32"],
    isoCode2: "BE",
    isoCode3: "BEL"
  },
  {
    country: "Italy",
    countryCodes: ["39"],
    isoCode2: "IT",
    isoCode3: "ITA"
  },
  {
    country: "Poland",
    countryCodes: ["48"],
    isoCode2: "PL",
    isoCode3: "POL"
  }
];

export const getCountryCodes = (countryString) => {
  let countryArray = countryString.replace(/And/g, ",").split(",");
  const result = countryArray.map((country) => {
    const foundCountry = codes.find(
      (item) => item.country.toLowerCase() === country.toLowerCase()
    );
    return foundCountry ? foundCountry.isoCode2 : null;
  });

  return result;
};

export const redirect = ({ redirectUrl, redirectUrlParams }) => {
  const params = `email=${redirectUrlParams.email}&firstname=${redirectUrlParams.firstName}&lastname=${redirectUrlParams.lastName}&phone=${redirectUrlParams.phone}`;
  window.location.replace(`${redirectUrl}?${params}`);
};

export const blurOnScroll = (target) => target.blur();

export const removeFloatingPointNumber = (event) =>
  ["e", "E", "+", "-", "."].includes(event.key) && event.preventDefault();

export const removeWhiteSpaces = ({ string }) =>
  string.replace(/\s+/g, " ").trim();
