import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  faUser,
  faPhone,
  faEnvelope,
  faCaretRight,
  faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Collapse, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Field from "./FormField";
import {
  FormSubText,
  FormHeadline,
  SubmitButton,
  LoaderContainer,
  FormSubHeadline,
  FormInnerContainer
} from "./ContactInfoForm.styles";
import {
  ContactPhoneSchema,
  ContactFormBaseSchema,
  ContactFormCompleteSchema
} from "../../services/validation-schema";
import PhoneInputField from "../PhoneInput";
import CustomNextButton from "../CustomNextButton";
import { getCountryCodes } from "../../services/utils";
import { useLeadAction } from "../../hooks/useLeadAction";
import { useEmailDomainConfirmation } from "../../hooks/useEmailDomainConfirmation";

const TermsPolicyDiv = styled.div`
  font-size: 10px;
`;

export const ContactInfoForm = ({ setLeadError, upcomingLeadInfo = null }) => {
  const [isLoading, setIsLoading] = useState();
  const {
    funnel,
    emailDomainConfirmation,
    leadForm: { isLandedFromPhoneReminderEmail }
  } = useSelector((state) => state.funnelInfo);
  const [isSubmittingFirstStep, setIsSubmittingFirstStep] = useState(false);
  const [firstStepSubmissionError, setFirstStepSubmissionError] = useState("");
  const [displayPhoneScreen, setDisplayPhoneScreen] = useState(
    isLandedFromPhoneReminderEmail
  );
  const { handleEmailDomainConfirmation } = useEmailDomainConfirmation();
  const {
    handleSubmitLeadInfo,
    checkPhoneNumberValidity,
    handleSubmitUpcomingLeadInfo
  } = useLeadAction();
  const textElements = funnel.textElements;
  const {
    formScreenHeader,
    formScreenSubtext,
    formHeadline,
    subHeadlineForm,
    fieldTitleName,
    fieldPlaceholderName,
    fieldTitleEmail,
    fieldPlaceholderEmail,
    fieldTitlePhone,
    fieldPlaceholderPhone,
    submitButton,
    submitButtonSubtext,
    numberCheck,
    enablePhoneWithTwoSteps,
    phoneScreenHeading
  } = textElements;
  const country = funnel.companyInfo?.country;

  const methods = useForm({
    resolver:
      displayPhoneScreen && enablePhoneWithTwoSteps
        ? yupResolver(ContactPhoneSchema)
        : !enablePhoneWithTwoSteps
          ? yupResolver(ContactFormCompleteSchema)
          : yupResolver(ContactFormBaseSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: ""
    }
  });

  const {
    watch,
    setError,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = methods;

  const emailWatcher = watch("email");

  const handleFirstStepSubmission = () => {
    if (emailDomainConfirmation || !isValid) return;
    handleSubmitUpcomingLeadInfo({
      formValues: getValues(),
      setDisplayNext: setDisplayPhoneScreen,
      setIsSubmitting: setIsSubmittingFirstStep,
      submissionError: setFirstStepSubmissionError
    });
  };

  const onSubmit = async (formValues) => {
    if (emailDomainConfirmation) return;
    const isValidPhoneNumber = checkPhoneNumberValidity({
      phoneNumber: formValues.phone,
      numberCheck,
      setError
    });
    if (!isValidPhoneNumber) return;

    let upcomingLeadDetails = null;
    // check if lead is coming from phone reminder email
    if (isLandedFromPhoneReminderEmail && upcomingLeadInfo) {
      const { fbclid, testMode, responses, leadZip, leadName, leadEmail } =
        upcomingLeadInfo;
      upcomingLeadDetails = {
        fbclid,
        leadZip,
        testMode,
        responses,
        formData: { name: leadName, email: leadEmail, phone: formValues.phone }
      };
    }
    handleSubmitLeadInfo({
      formValues,
      setLeadError,
      setIsLoading,
      upcomingLeadDetails
    });
  };

  useEffect(() => {
    handleEmailDomainConfirmation({
      hasError: errors?.email,
      emailValue: emailWatcher
    });
  }, [errors, emailWatcher, handleEmailDomainConfirmation]);

  return (
    <div>
      <div className="p-2 border border-1 border-white rounded-top bg-success text-center text-white">
        <p className="mb-0 fw-bolder">{formScreenHeader}</p>
        <FormSubText className="mb-0">{formScreenSubtext}</FormSubText>
      </div>
      <FormInnerContainer className="px-3 pt-3 mx-3 px-xsm-3 px-sm-3 px-xl-5 fs-6">
        <FormProvider {...methods}>
          <form
            onSubmit={(event) => {
              handleSubmit(onSubmit)(event);
            }}
          >
            {!displayPhoneScreen ? (
              <>
                <FormHeadline className="text-center px-5 fw-bolder fs-5">
                  {formHeadline}
                </FormHeadline>
                <FormSubHeadline className="text-center fs-7">
                  {subHeadlineForm}
                </FormSubHeadline>
                <Field
                  field={{
                    name: "name",
                    type: "text",
                    icon: faUser,
                    title: fieldTitleName,
                    hasError: errors?.name,
                    placeholder: fieldPlaceholderName
                  }}
                />
                <Field
                  field={{
                    name: "email",
                    type: "email",
                    icon: faEnvelope,
                    title: fieldTitleEmail,
                    hasError: errors?.email,
                    placeholder: fieldPlaceholderEmail
                  }}
                  suggestion={emailDomainConfirmation}
                />
                {enablePhoneWithTwoSteps && (
                  <div>
                    <TermsPolicyDiv className="text-secondary">
                      *Mit dem Klick auf den “Weiter” Button stellen Sie eine
                      Anfrage und akzeptieren unsere AGB und
                      Datenschutzerklärung. Wir kontaktieren Sie ausschließlich
                      zur Vervollständigung Ihrer Angaben. Ein kostenloser
                      Widerspruch ist jederzeit möglich.
                    </TermsPolicyDiv>
                    <div className="my-3">
                      <CustomNextButton
                        handleClick={() => {
                          handleSubmit(handleFirstStepSubmission)();
                        }}
                        nextBtnOpts={textElements}
                        isDisabled={isSubmittingFirstStep}
                        isForwarding={isSubmittingFirstStep}
                        fullWidth
                      />
                    </div>
                    <Collapse
                      in={
                        !isSubmittingFirstStep &&
                        firstStepSubmissionError !== ""
                      }
                    >
                      <div>
                        <div className="py-2 bg-danger text-white rounded ps-2">
                          <FontAwesomeIcon
                            icon={faXmarkCircle}
                            className="me-2"
                          />
                          {firstStepSubmissionError}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}
              </>
            ) : (
              <FormHeadline className="text-center px-5 fw-bolder fs-5 mt-3 mb-4">
                {phoneScreenHeading}
              </FormHeadline>
            )}

            {(displayPhoneScreen || !enablePhoneWithTwoSteps) && (
              <>
                <PhoneInputField
                  field={{
                    countries: getCountryCodes(country),
                    icon: faPhone,
                    name: "phone",
                    title: fieldTitlePhone,
                    hasError: errors?.phone,
                    placeholder: fieldPlaceholderPhone
                  }}
                />
                <SubmitButton
                  type="submit"
                  className="w-100 mt-3 border-0 outline-0"
                  disabled={isLoading}
                >
                  <div>
                    <div className="fs-5 fw-bolder">
                      {submitButton}
                      <FontAwesomeIcon icon={faCaretRight} className="ms-2" />
                    </div>
                    <div style={{ fontSize: "14px", opacity: "0.7" }}>
                      {submitButtonSubtext}
                    </div>
                  </div>
                  {isLoading && (
                    <LoaderContainer>
                      <Spinner />
                    </LoaderContainer>
                  )}
                </SubmitButton>
              </>
            )}
          </form>
        </FormProvider>
      </FormInnerContainer>
    </div>
  );
};
